import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../components/axiosInstance';
import SweetAlert from 'sweetalert2'
import formatAmount from 'indian-currency-formatter';

export default function PlanStatus({ planStatusClose, profile_id }) {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [statusType, setStatusType] = useState([]);
    const [activeStatus, setActiveStatus] = useState();
    const [monthly, setMonthly] = useState();
    const [yearly, setYearly] = useState();
    const [lumpsum, setLumpsum] = useState();
    // eslint-disable-next-line
    const [fpData, setFpData] = useState();

    const handleChange = (e) => {

        const { value } = e.target;

        if (e.target.name === 'monthly_amount') {
            setMonthly(formatAmount(value.replace(/,/g, "")));
        } else if (e.target.name === 'yearly_amount') {
            setYearly(formatAmount(value.replace(/,/g, "")));
        } else if (e.target.name === 'lumpsum_amount') {
            setLumpsum(formatAmount(value.replace(/,/g, "")));
        }



    };


    useEffect(() => {
        axioInstance.get(`fpplanstatus/get-status-type`).then((response, data) => {
            setStatusType(response.data);
        });

        axioInstance.post(`fpplanstatus/latest-status-check`, { profile_id: profile_id }
        ).then(
            (response, data) => {

                setFpData(response.data.plan_status);

            });

    }, [profile_id]);

    const defaultDate = new Date().toISOString().split("T")[0];

    const onSubmit = (Formdata) => {

        console.log('Formdata', Formdata);

        const status_type = Formdata.status_type;
        const status_date = Formdata.status_date;
        const status_remarks = Formdata.status_remarks
        var goal_summary = 0;
        const plan_type = 10;

        if (status_type === 3 || status_type === 4 || status_type === 5 || status_type === 6) {
            if (plan_type === 30) {
                goal_summary = 1;
            } else {

                axioInstance.post(`report-check`, { 'profile_id': profile_id }).then((response, data) => {

                    if (response.data.success === 1) {
                        goal_summary = 1;
                    } else {
                        SweetAlert.fire({
                            toast: true,
                            icon: 'error',
                            title: 'Goal Summary Is Not Generated',
                            animation: false,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                                toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                            }
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                });


            }


        } else {
            goal_summary = 1;
        }

        if (goal_summary === 1) {


            const PostData = {
                statusType: status_type,
                statusDate: status_date,
                statusRemarks: status_remarks,
                plan_type: plan_type,
                profile_id: profile_id,
                monthly_amount: Formdata.monthly_amount ? Formdata.monthly_amount.replace(/,/g, "") : 0,
                yearly_amount: Formdata.yearly_amount ? Formdata.yearly_amount.replace(/,/g, "") : 0,
                lumpsum_amount: Formdata.lumpsum_amount ? Formdata.lumpsum_amount.replace(/,/g, "") : 0,
                increase_by: Formdata.increase_by,
                admin_id: sessionStorage.getItem('admin_id')
            }

            axioInstance.post(`fpplanstatus/add-update`, PostData).then((response, data) => {

                SweetAlert.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Successfully Updated Plan Status',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                    }
                }).then(() => {

                    axioInstance.post(`profile/update-zoho-crm-profile`, { 'profile_id': profile_id })
                        .then(function (response) {
                            window.location.reload();
                        });


                });

            });
        }

    }

    return (


        <Fragment>

            <div className="innercontainer mb40">
                <div className="mainwrapper">


                    <div className={"row"}>
                        <div className={"col-lg-12"}>





                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="formtitle flex_center justify_center">
                                    <div>
                                        <div className="font20 fw600">Update Plan Status</div>
                                        <div className="font15 color495 "></div>
                                    </div>
                                    <div>


                                    </div>
                                </div>

                                {/* Form */}

                                <div className="form-group col-md-12 pl20 mt25">
                                    <label>Plan Status*</label>
                                    <select name="status_type" id="status_type" className="form-control" autoComplete='off' {...register("status_type", { required: 'Plan Status is required' })} onChange={(e) => setActiveStatus(e.target.value)}>
                                        <option selected disabled value={""}>select plan type</option>
                                        {
                                            statusType.map((data, key) => {
                                                let isDisabled = false;
                                                const fpDataInt = parseInt(fpData, 10);
                                                const dataIdInt = parseInt(data.id, 10);

                                                if (fpData !== null) {


                                                    if (fpDataInt < 5) {
                                                        if (fpDataInt === 1 && dataIdInt === 5) {
                                                            isDisabled = true;
                                                        } else if ([6, 7, 9].includes(dataIdInt)) {
                                                            isDisabled = true;
                                                        }
                                                    } else {
                                                        if ([6, 7, 9].includes(dataIdInt)) {
                                                            isDisabled = false;
                                                        }
                                                    }

                                                    // Additional condition to disable options below the current fpData
                                                    if (dataIdInt < fpDataInt) {
                                                        isDisabled = true;
                                                    }
                                                } else {
                                                    if ([5, 6, 7, 9].includes(dataIdInt)) {
                                                        isDisabled = true;
                                                    }
                                                }

                                                return (
                                                    <option key={key} value={data.id} disabled={isDisabled}>
                                                        {data.status}
                                                    </option>
                                                );
                                            })
                                        }
                                    </select>
                                    <span className="text-danger"> {errors.status_type && errors.status_type.message}</span>
                                </div>


                                <div className="form-group col-md-12 pl20">
                                    <label>Status Date*</label>
                                    <input type="date" name="status_date" className="default-date-picker form-control" id="status_date" placeholder="Set Date" defaultValue={defaultDate} autoComplete='off' {...register("status_date", { required: 'Status Date is required' })} />
                                    <span className="text-danger"> {errors.status_date && errors.status_date.message}</span>
                                </div>
                                <div className="form-group col-md-12 pl20">
                                    <label>Remarks*</label>
                                    <textarea id="status_remarks" name="status_remarks" className="form-control" placeholder="Status Remarks" autoComplete='off' {...register("status_remarks", { required: 'Remarks is required' })} ></textarea>
                                    <span className="text-danger"> {errors.status_remarks && errors.status_remarks.message}</span>
                                </div>

                                {
                                    (activeStatus === "7" || activeStatus === "9") && (
                                        <>
                                            <div className="form-group col-md-12 pl20">
                                                <label>Monthly Amount*</label>
                                                <input type="text" name="monthly_amount" className="default-date-picker form-control" id="monthly_amount" placeholder="Monthly Amount" autoComplete='off' {...register("monthly_amount", { required: 'Monthly Amount is required' })} value={monthly} onChange={(e) => handleChange(e)} />
                                                <span className="text-danger"> {errors.monthly_amount && errors.monthly_amount.message}</span>
                                            </div>

                                            <div className="form-group col-md-12 pl20">
                                                <label>Yearly Amount*</label>
                                                <input type="text" name="yearly_amount" className="default-date-picker form-control" id="yearly_amount" placeholder="Yearly Amount" autoComplete='off' {...register("yearly_amount", { required: 'Yearly Amount is required' })} value={yearly} onChange={(e) => handleChange(e)} />
                                                <span className="text-danger"> {errors.yearly_amount && errors.yearly_amount.message}</span>
                                            </div>

                                            <div className="form-group col-md-12 pl20">
                                                <label>Lumpsum Amount*</label>
                                                <input type="text" name="lumpsum_amount" className="default-date-picker form-control" id="lumpsum_amount" placeholder="Yearly Amount" autoComplete='off' {...register("yearly_amount", { required: 'Lumpsum Amount is required' })} value={lumpsum} onChange={(e) => handleChange(e)} />
                                                <span className="text-danger"> {errors.lumpsum_amount && errors.lumpsum_amount.message}</span>
                                            </div>

                                            <div className="form-group col-md-12 pl20">
                                                <label>Yearly investment increment*</label>
                                                <input type="text" name="increase_by" className="default-date-picker form-control" id="increase_by" placeholder="Yearly investment increment" autoComplete='off' {...register("increase_by", {
                                                    required: 'Yearly investment increment is required', pattern: {
                                                        value: /^[0-9]+$/,   // This pattern ensures only digits are allowed
                                                        message: "Please enter a only number without(%)"
                                                    }
                                                })} />
                                                <span className="text-danger"> {errors.increase_by && errors.increase_by.message}</span>
                                            </div>
                                        </>
                                    )
                                }



                                <div className="form_prnt pt25 pl35 pr35 pb35">
                                    <div className="updateStatusBtn backsaveBtn">
                                        <button type="submit" className="btn btn-primary">Update </button>
                                        <button type="button" className="btn btn-primary" onClick={() => planStatusClose(false)}>Cancel </button>
                                    </div>
                                </div>



                            </form>


                            {/* Form End */}

                        </div>


                    </div>

                </div>
            </div>

        </Fragment>
    )
}
